import React, { useEffect, useMemo, useRef, useCallback } from 'react';
import { useRemark } from 'react-remark';
import { IconArrowBackUp, IconArrowForwardUp } from '@tabler/icons-react';
import {
  assetsTextUpdate,
  assetsTextRegenerateClipsCreate,
  assetsTextRegenerateClipForSavedSearchCreate
} from '@goldcast/api/content';
import { useParams } from 'react-router-dom';
import { countWords } from '../utils';
import PostClipWrapper from './PostClipWrapper';
import { SelectedVoiceProfile, TextAsset, TextConfig } from '../types';
import ClipListModal from './ClipListModal';
import PostClipEmptyState from './PostClipEmptyState';
import PostUpdateSpinner from './PostUpdateSpinner';
import ChangeVoice from './ChangeVoice';
import PostOutputSkeletonLoader from './PostOutputSkeletonLoader';
import EventBus from '@/libs/eventBus/eventBus';
import { CustomEvents } from '@/libs/eventBus/constants';
import TextEditor from '@/components/organisms/TextEditor/TextEditor';
import { TextEditorRef } from '@/components/organisms/TextEditor/types';
import { useAppContext } from '@/context/AppContext/AppContext';
import usePost from '@/hooks/usePost';
import { core } from '@/stores/core';
import { POST_TYPES } from '@/Pages/PostsListPage/constants';
import FreeTrialUpgradeNag from '@/components/molecules/FreeTrial/FreeTrialUpgradeNag';
import SessionTitlePopup from '@/Pages/SessionDetail/SessionTitlePopup';
import PostSharePopup from '@/components/molecules/SharePopup/PostSharePopup/PostSharePopup';
import useDebounce from '@/hooks/useDebounce';
import { showErrorToast, showSuccessToast } from '@/libs/toast/toast';
import ClipPlayerWrapper from '@/Pages/Clip/ClipPlayer/ClipPlayerWrapper';
import useAnalytics from '@/hooks/useAnalytics';
import DownloadsPopup from '@/components/molecules/DownloadsPopup/DownloadsPopup';
import { resetInternalMetadata, setInternalMetadata } from '@/stores/debugToolsStores';

export default function PostOutputEditor({
  output,
  selectedPost,
  isGeneratingPost,
  isUpdating,
  isRewriting,
  postType,
  updateTextChange,
  onSelectedAssetUpdate,
  onActiveClipChange,
  onPostShared,
  onSelectedVoiceProfile
}: {
  output: string;
  selectedPost: TextAsset | null;
  isGeneratingPost: boolean;
  isUpdating: boolean;
  isRewriting: boolean;
  postType: string;
  updateTextChange: ({ output, title }: { output: string; title: string }) => void;
  onSelectedAssetUpdate: (updatedAssetMetadata) => void;
  onActiveClipChange: (index: number) => void;
  onPostShared: () => void;
  onSelectedVoiceProfile: ({ value, type }: SelectedVoiceProfile) => void;
}) {
  const editorRef = useRef<TextEditorRef>(null);
  const { broadcastId } = useParams<{ broadcastId: string }>();
  const [wordsCount, setWordsCount] = React.useState(0);
  const [reactContent, setMarkdownSource] = useRemark();
  const { logger } = useAppContext();
  const { trackContentCreated, trackContentEdited } = useAnalytics();
  const {
    isReplaceClipDialogOpen,
    openReplaceClipDialog,
    closeReplaceClipDialog,
    getSelectedClipId,
    getClipAssetIds,
    getUpdatedAssetOnReplace,
    onBlogClipSelection
  } = usePost();

  const isBlogPost = useMemo(
    () => selectedPost?.asset_metadata.type === POST_TYPES.BLOG,
    [selectedPost?.asset_metadata.type]
  );

  const selectedClipId = useMemo(() => {
    if (isGeneratingPost || !selectedPost) {
      return undefined;
    }

    return getSelectedClipId(selectedPost.asset_metadata);
  }, [selectedPost, isGeneratingPost, getSelectedClipId]);

  const showClipEmptyState = useMemo(() => {
    const content = core.getSnapshot().content;

    if (isGeneratingPost || !content?.multi_modal_enabled) {
      return false;
    }

    return selectedPost?.asset_metadata.type === 'LINKEDIN';
  }, [selectedPost, isGeneratingPost]);

  useEffect(() => {
    setMarkdownSource(output);
  }, [setMarkdownSource, output]);

  useEffect(() => {
    if (!selectedPost?.asset_metadata?.internal) return;
    setInternalMetadata(selectedPost.asset_metadata.internal);

    return () => {
      resetInternalMetadata();
    };
  }, [selectedPost?.asset_metadata?.internal]);

  const updateWordsCount = useCallback(() => {
    const containerWordCount = countWords(output);
    const titleWordCount = countWords(selectedPost?.title || '');

    setWordsCount(containerWordCount + titleWordCount);
  }, [output, selectedPost?.title]);

  useEffect(() => {
    updateWordsCount();
  }, [reactContent, updateWordsCount]);

  const handleInputChange = (editorData: { title: string; markdownBody: string }) => {
    const { markdownBody: text, title } = editorData;
    updateTextChange({ title, output: text });
    updateWordsCount();
  };

  const debouncedInputChange = useDebounce(handleInputChange, 2000);

  const handleClipSelection = clip => {
    if (!selectedPost) return;
    const assetMetadata = getUpdatedAssetOnReplace(clip, selectedPost.asset_metadata);
    const updatedAssetMetadata: TextConfig = {
      ...selectedPost.asset_metadata,
      ...assetMetadata,
      clip_assets: [clip.asset_metadata.id]
    };
    assetsTextUpdate({
      id: selectedPost?.id || '',
      body: { asset_metadata: updatedAssetMetadata, title: selectedPost?.title || '', shared: selectedPost?.shared }
    })
      .then(() => {
        onSelectedAssetUpdate(updatedAssetMetadata);
        closeReplaceClipDialog();
        trackContentEdited({
          asset: selectedPost,
          isSuccess: true,
          updateType: 'ReplaceClip'
        });
        showSuccessToast('Clip replaced successfully');
      })
      .catch(e => {
        logger.error(e);
        trackContentEdited({
          asset: selectedPost,
          isSuccess: false,
          updateType: 'ReplaceClip'
        });
        showErrorToast('Whoops! We hit a snag while replacing clip. Please try again.');
      });
  };

  const handleRemoveClip = () => {
    if (!selectedPost) return Promise.resolve();
    const updatedAssetMetadata: TextConfig = {
      ...selectedPost.asset_metadata,
      clip_asset_ids: [],
      clip_assets: []
    };
    return assetsTextUpdate({
      id: selectedPost?.id || '',
      body: { asset_metadata: updatedAssetMetadata, title: selectedPost?.title || '' }
    })
      .then(() => {
        onSelectedAssetUpdate(updatedAssetMetadata);
        showSuccessToast('Clip removed successfully');
      })
      .catch(e => {
        logger.error(e);
        showErrorToast('Whoops! We hit a snag while removing clip. Please try again.');
      });
  };

  const onRegenerateClip = async (isSavedSearchPage: boolean) => {
    if (!selectedPost) return;

    try {
      trackContentCreated({
        asset: selectedPost,
        source: 'Regenerate',
        state: 'Started',
        isMultimodal: true
      });

      const res = isSavedSearchPage
        ? await assetsTextRegenerateClipForSavedSearchCreate({ id: selectedPost.id })
        : await assetsTextRegenerateClipsCreate({ id: selectedPost.id });

      const updatedAssetMetadata: TextConfig = {
        ...selectedPost.asset_metadata,
        clip_asset_ids: res.asset_metadata.clip_asset_ids,
        currentClipIndex: res.asset_metadata.currentClipIndex,
        activeClipIndex: res.asset_metadata.activeClipIndex
      };
      onSelectedAssetUpdate(updatedAssetMetadata);
      EventBus.dispatch(CustomEvents.ReloadAllClips);
      showSuccessToast('Clip regenerated successfully');
      trackContentCreated({
        asset: selectedPost,
        source: 'Regenerate',
        state: 'Created'
      });
      trackContentEdited({
        asset: selectedPost,
        isSuccess: true,
        updateType: 'RegenerateClip'
      });
    } catch (e) {
      logger.error(e);
      showErrorToast('No more new clips found matching social post content.');
      trackContentCreated({
        asset: selectedPost,
        source: 'Regenerate',
        state: 'Failed'
      });
      trackContentEdited({
        asset: selectedPost,
        isSuccess: false,
        updateType: 'RegenerateClip'
      });
    }
  };

  const handleBlogClipSelection = clip => {
    if (!selectedPost) return;
    trackContentEdited({
      asset: selectedPost,
      isSuccess: true,
      updateType: 'ReplaceClip'
    });
    onBlogClipSelection(clip);
  };

  const handleTranscriptError = useCallback(() => {
    throw new Error('Failed to fetch transcript');
  }, []);

  const handleUndo = () => {
    if (editorRef.current) {
      editorRef.current.undo();
    }
  };

  const handleRedo = () => {
    if (editorRef.current) {
      editorRef.current.redo();
    }
  };

  const clipAssetIds = useMemo(
    () => getClipAssetIds(selectedPost?.asset_metadata),
    [selectedPost?.asset_metadata, getClipAssetIds]
  );

  return (
    <>
      <div className="sticky top-0 z-30 flex w-full items-center justify-between p-4 pb-3.5">
        <div className="flex w-full items-center justify-between">
          <div className="z-50 flex -translate-x-3 items-center">
            <SessionTitlePopup />
            <PostUpdateSpinner updatedAt={selectedPost?.updated_at || ''} isUpdating={isUpdating || isGeneratingPost} />
          </div>
          <div className="flex items-center space-x-2">
            <FreeTrialUpgradeNag />
            {broadcastId && <DownloadsPopup contentId={broadcastId} />}
            <div className="mr-3 flex space-x-0.5">
              <button className="flex h-10 w-10 items-center justify-center rounded-md" onClick={handleUndo}>
                <IconArrowBackUp />
              </button>
              <button className="flex h-10 w-10 items-center justify-center rounded-md" onClick={handleRedo}>
                <IconArrowForwardUp />
              </button>
            </div>
            {!isGeneratingPost && <PostSharePopup selectedPost={selectedPost} onPostShared={onPostShared} />}
          </div>
        </div>
      </div>
      <div className="flex w-full grow items-center justify-center overflow-hidden px-2 pb-2 pr-4">
        <div className="relative flex h-full w-full grow flex-col items-center overflow-scroll rounded-xl bg-white">
          <div className="sticky top-0 z-10 flex w-full bg-white p-2.5 transition-shadow duration-150 ease-in-out">
            <ChangeVoice
              isRewriting={isRewriting}
              postType={postType}
              onSelectedVoiceProfile={onSelectedVoiceProfile}
            />
          </div>
          <div className="mt-4 w-full max-w-3xl">
            {isRewriting ? (
              <div className="mt-10">
                <PostOutputSkeletonLoader />
              </div>
            ) : (
              <>
                {isGeneratingPost ? (
                  <div
                    key={output}
                    className="editor prose w-full max-w-3xl focus:outline-none"
                    suppressContentEditableWarning={true}
                  >
                    {reactContent}
                  </div>
                ) : (
                  <TextEditor
                    ref={editorRef}
                    key={selectedPost?.id}
                    title={selectedPost?.title || ''}
                    text={output}
                    isMultimodalBlogPost={isBlogPost}
                    onChange={debouncedInputChange}
                  />
                )}
              </>
            )}
          </div>
          {selectedClipId ? (
            <div className="box-border min-h-[552px] w-full max-w-[46rem] border !border-slate-100 bg-slate-50 shadow-sm">
              <ClipPlayerWrapper clipId={selectedClipId} handleTranscriptError={handleTranscriptError}>
                <PostClipWrapper
                  openReplaceClipDialog={openReplaceClipDialog}
                  onRemoveClip={handleRemoveClip}
                  onRegenerateClip={onRegenerateClip}
                  activeClip={selectedPost?.asset_metadata?.currentClipIndex || 0}
                  clipAssetIds={clipAssetIds}
                  handleActiveClipChange={onActiveClipChange}
                />
              </ClipPlayerWrapper>
            </div>
          ) : (
            showClipEmptyState && <PostClipEmptyState importClip={openReplaceClipDialog} />
          )}
        </div>
      </div>
      <div className="fixed bottom-4 right-4 rounded-full bg-slate-300/70 px-2.5 py-1 text-xs font-medium text-slate-900">
        {wordsCount} words
      </div>
      {isReplaceClipDialogOpen && (
        <ClipListModal
          isOpen={isReplaceClipDialogOpen}
          onClose={() => closeReplaceClipDialog()}
          onClipSelection={isBlogPost ? handleBlogClipSelection : handleClipSelection}
        />
      )}
    </>
  );
}
